import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Routess } from "../routes";
import Sidebar from "../components/Sidebar";
import Presentation from "./Presentation";
import DashboardOverview from "./dashboard/DashboardOverview";
import AddUser from "./users/AddUser";
import AddLink from "./addLinks";
import DetailPage from "./detailsPage/DetailPage";
import UpdateUser from "./users/UpdateUser";
import Signin from "./examples/Signin";
import Logout from "./examples/Logout";
import Signup from "./examples/Signup";
import ChangePassword from "./examples/ChangePassword";
import ResetPassword from "./examples/ResetPassword";
import ForgotPassword from "./examples/FrogotPasword";
import NotFoundPage from "./examples/NotFound";
import EmailVerify from "./examples/EmailVerify";
import FeedBack from "./feedback/FeedBack";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Navs from "./components/Navs";
import Navbars from "./components/Navbars";
import Pagination from "./components/Pagination";
import WebsiteAddMob from "./addLinks/WebsiteAddMob";
import ImportCSVMob from "./addLinks/ImportCSVMob";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../config/config";
const App = () => {
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const tokenFromLocal = localStorage.getItem("trp_token");
    if (tokenFromLocal) {
      setToken(tokenFromLocal);
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {token ? (
        <>
          <Sidebar />
          <div className="content">
            <Navbar />
            <Routes>
              <Route
                path={Routess.Presentation.path}
                element={<Presentation />}
              />
              <Route path={Routess.Logout.path} element={<Logout />} />
              <Route path={Routess.NotFound.path} element={<NotFoundPage />} />
              <Route
                path={Routess.ChangePassword.path}
                element={<ChangePassword />}
              />
              <Route path={Routess.FeedBack.path} element={<FeedBack />} />
              <Route
                path={Routess.DashboardOverview.path}
                element={<DashboardOverview />}
              />
              <Route path={Routess.DetailPage.path} element={<DetailPage />} />
              <Route path={Routess.AddUser.path} element={<AddUser />} />
              <Route path={Routess.AddLink.path} element={<AddLink />} />
              <Route
                path={Routess.WebsiteAddMob.path}
                element={<WebsiteAddMob />}
              />
              <Route
                path={Routess.ImportCSVMob.path}
                element={<ImportCSVMob />}
              />
              <Route path={Routess.UpdateUser.path} element={<UpdateUser />} />
              <Route path={Routess.Navs.path} element={<Navs />} />
              <Route path={Routess.Navbars.path} element={<Navbars />} />
              <Route path={Routess.Pagination.path} element={<Pagination />} />
            </Routes>
            <Footer />
          </div>
        </>
      ) : (
        <div>
          <Routes>
            <Route path={Routess.Signin.path} element={<Signin />} />
            <Route
              path={Routess.ForgotPassword.path}
              element={<ForgotPassword />}
            />
            <Route path={Routess.Signup.path} element={<Signup />} />
            <Route
              path={Routess.ResetPassword.path}
              element={<ResetPassword />}
            />
            <Route path={Routess.EmailVerify.path} element={<EmailVerify />} />
            <Route path={Routess.NotFound.path} element={<NotFoundPage />} />
          </Routes>
        </div>
      )}
    </>
  );
};

export default App;
